import dynamic from 'next/dynamic';

export default {
  ArticlePage: dynamic(() => import('./ArticlePage')),
  BasePage: dynamic(() => import('./BasePage')),
  HomePage: dynamic(() => import('./HomePage')),
  WhatWeOfferPage: dynamic(() => import('./WhatWeOfferPage')),
  InsightsPage: dynamic(() => import('./InsightsPage')),
  NotFoundPage: dynamic(() => import('./NotFoundPage')),
  PasswordProtectedPage: dynamic(() => import('./PasswordProtectedPage')),
  PureHtmlPage: dynamic(() => import('./PureHtmlPage')),
  ReportPage: dynamic(() => import('./ReportPage')),
  RichTextPage: dynamic(() => import('./RichTextPage')),
  ContactPage: dynamic(() => import('./ContactPage')),
  WhoWeArePage: dynamic(() => import('./WhoWeArePage')),
  WhatWeOfferCategoryPage: dynamic(() => import('./WhatWeOfferCategoryPage')),
  SurveyPage: dynamic(() => import('./SurveyPage')),
};
